<template>
  <section class="section">
    <h2 class="service subtitle">
      Предоставление доступа заявителям по услуге "Аттестация"
    </h2>

    <b-tabs type="is-toggle" v-model="activeTab">
      <b-tab-item label="Разрешение по ИИН:учителя">
        <div class="field">
          <label for="name" class="label">Поиск по ИИН</label>
          <div class="control">
            <input v-model="filter.iin" type="text" id="search" class="input" />
          </div>
          <div class="control">
            <button
              class="button is-primary"
              @click="search('teachers_attestation')"
            >
              Искать
            </button>
          </div>
        </div>

        <div v-if="showClaims">
          <table class="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>ИИН</th>
                <th>{{ $ml.get("status") }}</th>
                <th>{{ $ml.get("creation_date") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                :key="'table_item_' + itemIndex"
                v-for="(item, itemIndex) in teachers_attestation"
              >
                <td>{{ item.declarer_iin }}</td>
                <td>{{ translateStatus(item.status.code) }}</td>
                <td>{{ item.created_at }}</td>
              </tr>
            </tbody>
          </table>
          <div class="limit-changes-history" v-if="teachers_history.length > 0">
            <label class="label">История</label>
            <table class="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>№</th>
                  <th>Старое значение</th>
                  <th>Новое значение</th>
                  <th>{{ $ml.get("creation_date") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :key="'table_item_' + itemIndex"
                  v-for="(item, itemIndex) in teachers_history"
                >
                  <td>{{ item.id }}</td>
                  <td>{{ item.old_value }}</td>
                  <td>{{ item.new_value }}</td>
                  <td>{{ item.created_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <footer class="modal-card-foot">
            <button
              class="button is-info"
              @click="allow('teachers_attestation')"
            >
              Предоставить доступ для этого ИИН
            </button>
          </footer>
        </div>
        <div v-else-if="notice">
          <h3>{{ this.notice }}</h3>
        </div>
      </b-tab-item>

      <b-tab-item label="Разрешение по ИИН:руководители">
        <div class="field">
          <label for="name" class="label">Поиск по ИИН</label>
          <div class="control">
            <input v-model="filter.iin" type="text" id="search" class="input" />
          </div>
          <div class="control">
            <button
              class="button is-primary"
              @click="search('principals_attestation')"
            >
              Искать
            </button>
          </div>
        </div>

        <div v-if="showClaims">
          <table class="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>ИИН</th>
                <th>{{ $ml.get("status") }}</th>
                <th>{{ $ml.get("creation_date") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                :key="'table_item_' + itemIndex"
                v-for="(item, itemIndex) in principals_attestation"
              >
                <td>{{ item.declarer_iin }}</td>
                <td>{{ translateStatus(item.status.code) }}</td>
                <td>{{ item.created_at }}</td>
              </tr>
            </tbody>
          </table>
          <div
            class="limit-changes-history"
            v-if="principals_history.length > 0"
          >
            <label class="label">История</label>
            <table class="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>№</th>
                  <th>Старое значение</th>
                  <th>Новое значение</th>
                  <th>{{ $ml.get("creation_date") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :key="'table_item_' + itemIndex"
                  v-for="(item, itemIndex) in principals_history"
                >
                  <td>{{ item.id }}</td>
                  <td>{{ item.old_value }}</td>
                  <td>{{ item.new_value }}</td>
                  <td>{{ item.created_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <footer class="modal-card-foot">
            <button
              class="button is-info"
              @click="allow('principals_attestation')"
            >
              Предоставить доступ для этого ИИН
            </button>
          </footer>
        </div>
        <div v-else-if="notice">
          <h3>{{ this.notice }}</h3>
        </div>
      </b-tab-item>

      <b-tab-item label="Массовое разрешение">
        <div class="field">
          <b-switch
            v-model="canCallNewClaimTeachers"
            :true-value="show"
            :false-value="hide"
          >
            {{ canCallNewClaimTeachers }} кнопку "Добавить заявку" в разделе
            "Аттестация учителей"
          </b-switch>
        </div>
        <div class="field">
          <b-switch
            v-model="canCallNewClaimPrincipals"
            :true-value="show"
            :false-value="hide"
          >
            {{ canCallNewClaimPrincipals }} кнопку "Добавить заявку" в разделе
            "Аттестация руководителей"
          </b-switch>
        </div>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import statuses from "@/configs/claim-statuses";
import _ from "lodash";
export default {
  name: "TeacherAttestationAllow",
  data() {
    let _hide = "Спрятать",
      _show = "Показать";

    return {
      filter: {
        iin: null
      },
      items: [[], []],
      notice: null,
      noticeList: {
        empty:
          "Для этого ИИН заявителя еще нет заявления для предоставления доступа",
        notEndedStatus:
          "Для этого ИИН заявителя еще нет завершенных заявлении для предоставления доступа",
        wrongIIN: "Введённые ИИН некорректные",
        success: "Доступ предоставлен"
      },
      apiResource: this.$apiResource.make("Claims"),
      usersApi: this.$apiResource.make("Users"),
      settingsApi: this.$apiResource.make("Settings"),
      settingsAdminApi: this.$apiResource.make("SettingsAdmin"),
      activeTab: 0,
      canCallNewClaimTeachers: _hide,
      canCallNewClaimPrincipals: _hide,
      hide: _hide,
      show: _show,
      repository: [],
      teachers_attestation: [],
      principals_attestation: []
    };
  },
  computed: {
    ...mapGetters({
      services: "services/getList"
    }),
    showClaims() {
      let list = ["teachers_attestation", "principals_attestation"];
      return (
        !this.notice &&
        this[list[this.activeTab]] &&
        this[list[this.activeTab]].length > 0
      );
    },
    teachers_history() {
      let history =
        this.user.claim_limit_histories.filter(
          i => i.slug == "teachers-attestation"
        ) || [];
      if (
        this.user.claim_limit_histories.length === 0 &&
        this.user.limit_claims_teachers > 0
      ) {
        history = [
          {
            id: 1,
            old_value: this.user.limit_claims_teachers - 1,
            new_value: this.user.limit_claims_teachers,
            user_id: this.user.id,
            created_at: this.user.updated_at,
            slug: "teachers-attestation"
          }
        ];
      }
      return history;
    },
    principals_history() {
      let history =
        this.user.claim_limit_histories.filter(
          i => i.slug == "principals-attestation"
        ) || [];
      if (
        this.user.claim_limit_histories.length === 0 &&
        this.user.limit_claims_principals > 0
      ) {
        history = [
          {
            id: 1,
            old_value: this.user.limit_claims_principals - 1,
            new_value: this.user.limit_claims_principals,
            user_id: this.user.id,
            created_at: this.user.updated_at,
            slug: "principals-attestation"
          }
        ];
      }
      return history;
    }
  },
  watch: {
    canCallNewClaimTeachers(v) {
      if (this.repository["canCallNewClaimTeachers"]) {
        this.repository["canCallNewClaimTeachers"].value =
          v === this.show ? "true" : "false";
        this.settingsAdminApi.update(
          this.repository["canCallNewClaimTeachers"].id,
          this.repository["canCallNewClaimTeachers"]
        );
      }
    },
    canCallNewClaimPrincipals(v) {
      if (this.repository["canCallNewClaimPrincipals"]) {
        this.repository["canCallNewClaimPrincipals"].value =
          v === this.show ? "true" : "false";
        this.settingsAdminApi.update(
          this.repository["canCallNewClaimPrincipals"].id,
          this.repository["canCallNewClaimPrincipals"]
        );
      }
    },
    activeTab() {
      this.reset();
    }
  },
  methods: {
    ...mapActions("services", ["fetchEmploymentTypes"]),
    translateStatus(statusCode) {
      return this.$ml.get(statuses[statusCode].translate) || statusCode;
    },
    allow(slug) {
      let fieldNames = {
        teachers_attestation: "limit_claims_teachers",
        principals_attestation: "limit_claims_principals"
      };

      if (this[slug][this[slug].length - 1].status.code !== "ENDED") {
        this.notice = this.noticeList["notEndedStatus"];
      } else {
        let values = {};

        values[fieldNames[slug]] = this.user[fieldNames[slug]] + 1;

        this.usersApi
          .update(this.user.id, values)
          .then(() => {
            this.notice = this.noticeList["success"];
          })
          .catch(error => {
            displayError(this, getErrorsText(error));
          });
      }
    },
    reset() {
      this.filter.iin = null;
      this.notice = null;
      this.teachers_attestation = [];
      this.principals_attestation = [];
    },
    search(slug) {
      this.reset();
      const _slug = slug.replace("_", "-");
      this.apiResource
        .managersList({
          declarer_type: null,
          search_by_child: 0,
          slug: slug,
          service_id: this.services.find(service => service.slug === _slug)[
            "id"
          ],
          iin: this.filter.iin
        })
        .then(response => {
          const data = response["data"];

          if (data["data"].length > 0) {
            this[slug] = data["data"].sort(
              (a, b) => Number(a.id) - Number(b.id)
            );
          } else {
            this.notice = this.noticeList["empty"];
          }
        });

      this.usersApi
        .list({
          search_query: this.filter.iin
        })
        .then(response => {
          const data = response["data"];
          this.user = data["data"][0];
        });
    }
  },
  beforeMount() {
    this.fetchEmploymentTypes();
    this.settingsApi.list({ tag: "teachers-attestation" }).then(response => {
      let canCallNewClaimTeachers = _.find(response.data, {
        name: "add_claim_for_all"
      });
      if (canCallNewClaimTeachers) {
        this.repository["canCallNewClaimTeachers"] = canCallNewClaimTeachers;

        if (canCallNewClaimTeachers.value_type_name == "bool") {
          if (canCallNewClaimTeachers.value == "true") {
            this.canCallNewClaimTeachers = this.show;
          }
        }
      }
    });

    this.settingsApi.list({ tag: "principals-attestation" }).then(response => {
      let canCallNewClaimPrincipals = _.find(response.data, {
        name: "add_claim_for_all"
      });
      if (canCallNewClaimPrincipals) {
        this.repository[
          "canCallNewClaimPrincipals"
        ] = canCallNewClaimPrincipals;

        if (canCallNewClaimPrincipals.value_type_name == "bool") {
          if (canCallNewClaimPrincipals.value == "true") {
            this.canCallNewClaimPrincipals = this.show;
          }
        }
      }
    });
  }
};
</script>
